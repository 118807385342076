export const styles = {
  simpleGrid: {
    gap: '20px',
    border: '1px',
    borderRadius: '2xl',
    borderColor: '#D9D9D9',
    padding: '10px',
    bg: '#F6F6F6',
  },
  titleText: {
    fontSize: '24px',
    margin: '40px 0px 20px 20px',
    fontWeight: 'bold',
  },
}

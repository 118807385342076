import { FormLabel } from '@chakra-ui/react'

export default function OutlineLabel(props: { name: string; id: string }) {
  return (
    <FormLabel
      fontSize={'small'}
      marginBottom={'-10px'}
      marginLeft={'20px'}
      position={'inherit'}
      zIndex={'1'}
      htmlFor={props.id.toLowerCase()}
      children={props.name}
    />
  )
}

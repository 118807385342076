import {
  getToken as getTokenLocal,
  setToken as setTokenLocal,
  getUser as getUserLocal,
  setUser as setUserLocal,
  removeToken as removeTokenLocal,
  removeUser as removeUserLocal,
  getExpiresIn as getExpiresInLocal,
  removeExpiresIn as removeExpiresInLocal,
  setExpiresIn as setExpiresInLocal,
} from '../utils/local-storage'
import { axiosInstance } from '../services/axios/index'
import { useEffect, useState, createContext, useContext } from 'react'
import decode from 'jwt-decode'

const isSessionExpired = (expiresIn) => {
  const now = Math.floor(Date.now() / 1000)
  return now < expiresIn
}

const GlobalContext = createContext()

const useGlobalContext = () => {
  const context = useContext(GlobalContext)
  if (!context) throw new Error('Use globabl context must be used within global provider')
  return context
}

const GlobalProvider = (props) => {
  const [token, setToken] = useState(getTokenLocal())
  const [user, setUser] = useState(getUserLocal())
  const [expiresIn, setExpiresIn] = useState(getExpiresInLocal())

  const login = async ({ email, password }) => {
    try {
      const response = await axiosInstance.post('/auth/login', { email, password })

      if (response.data) {
        const { accessToken } = response.data
        setToken(accessToken)
        setTokenLocal(accessToken)

        const decoded = decode(accessToken)
        setUser(decoded)
        setUserLocal(decoded)
        setExpiresIn(decoded.exp)
        setExpiresInLocal(decoded.exp)
      }
    } catch (ex) {
      console.log(ex)
    }
  }

  const logout = async () => {
    try {
      setToken(null)
      removeTokenLocal()
      setUser(null)
      removeUserLocal()
      setExpiresIn(null)
      removeExpiresInLocal()
    } catch (ex) {
      console.log(ex)
    }
  }

  const isExpired = () => !(expiresIn && !isSessionExpired(expiresIn))

  const checkAuth = () => token && !isExpired()

  let isLogin = false
  if (token) {
    if (isSessionExpired(expiresIn)) {
      isLogin = true
    }
  } else isLogin = false

  const value = { user, isExpired, checkAuth, login, logout, isLogin }

  useEffect(() => {
    const init = () => {}
    // !isExpired() && init();
    init()
  }, [token, user, expiresIn])

  return <GlobalContext.Provider {...props} value={value} />
}

export { useGlobalContext, GlobalProvider }

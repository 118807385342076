import { CSSReset, ChakraProvider } from '@chakra-ui/react'
import { GlobalProvider } from './providers/globalProvider'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { AppRoutes } from './routes/Routes'
import './App.css'

function App() {
  return (
    <GlobalProvider>
      <ChakraProvider>
        <CSSReset />
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </ChakraProvider>
      <ToastContainer />
    </GlobalProvider>
  )
}

export default App

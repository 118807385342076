import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { CloseIcon } from '@chakra-ui/icons'

export default function Error() {
  return (
    <Box textAlign='center' py={10} px={6}>
      <Box display='inline-block'>
        <Flex
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          bg={'red.500'}
          rounded={'50px'}
          w={'55px'}
          h={'55px'}
          textAlign='center'
          children={<CloseIcon boxSize={'20px'} color={'white'} />}
        />
      </Box>
      <Heading as='h2' size='xl' mt={6} mb={2} children={'Access Denied'} />
      <Text
        color={'gray.500'}
        children={`You tried to access a protected page while not logged in. In a real app, this could redirect you to a login page, or back to the homepage.`}
      />
    </Box>
  )
}

import { Text, SimpleGrid, Box } from '@chakra-ui/react'
import { styles } from './styles'

export default function GeneralCard(props: {
  title: string
  children: any
  customWidth?: string
  customColumn?: { base: number; md: number; lg: number; '2xl': number }
}) {
  const defaultColumns = { base: 1, md: 1, lg: 1, '2xl': 1 }
  const customColumn = props.customColumn || defaultColumns

  const defaultWidth = 'auto'
  const customWidth = props.customWidth || defaultWidth

  return (
    <Box margin={'20px 40px 40px 40px'} width={customWidth}>
      <Text sx={styles.titleText} children={props.title} />
      <SimpleGrid sx={styles.simpleGrid} columns={customColumn} children={props.children} />
    </Box>
  )
}

import React from 'react'
import { useGlobalContext } from '../providers/globalProvider'
import Dashboard from '../pages/dashboard/dashboard'
import { withCondition } from './withCondition'
import Login from '../pages/auth/login'

/** A higher-order wrapper, binding the "user logged in" condition and redirect */
export const withLoggedIn = (Component: React.FunctionComponent) => {
  const { isLogin } = useGlobalContext()
  return withCondition(Component, isLogin, '/login')
}

/** The inverse, showing a page only if a user is logged OUT */
export const withLoggedOut = (Component: React.FunctionComponent) => {
  const { isLogin } = useGlobalContext()
  return withCondition(Component, !isLogin, '/dashboard')
}

export const forLoginPage = () => {
  const { checkAuth } = useGlobalContext()
  return checkAuth() ? <Dashboard /> : <Login />
}

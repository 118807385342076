import { toast } from 'react-toastify'
import React from 'react'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    logErrorToMyService(error, errorInfo)
  }

  render() {
    // const navigate = useNavigate()
    // navigate('login')
    if (this.state.hasError) {
      toast('An error occurred while loading the  data.')
      // You can render any custom fallback UI
      return <h1 children='Something went wrong.' />
    }

    return this.props.children
  }
}

export default ErrorBoundary
